<template>

  <div class="ms-grid" v-if="getAcf">
    <div class="fast-facts-wrapper1">
      <div class="fast-facts-wrapper2">
        <div class="fast-facts"
        id="fast-facts"
        role="region"
        aria-label="Fast Facts">
          <ul class="fast-facts">
            <template v-for="num in countAssignedFastFacts">
              <li v-if="posts[num].ID"
              :key="num"
              class="fast-facts">
                <span class="loading-animation" v-if="!posts[num]"></span>
                <template v-if="posts[num]">
                  <h1 class="fast-facts-text" v-html="posts[num].acf.header"></h1>
                  <h2 class="fast-facts-text" v-html="posts[num].acf.sub_header"></h2>
                  <div class="fast-facts-text-horizontal-rule"></div>
                  <span class="fast-facts-text" v-html="posts[num].acf.description_text"></span>
                </template>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'FastFacts',
  data() {
    return {
      posts: [{}, {}, {}],
      // loading: true,
    };
  },
  computed: {
    getAcf() {
      return this.$store.state.acf;
    },
    getPost() {
      return this.$store.state.postFastFacts;
    },
    assignedFastFacts() {
      return this.getAcf.uic_home.fast_facts;
    },
    countAssignedFastFacts() {
      let number = 0;
      Object.keys(this.assignedFastFacts).forEach((i) => {
        if (this.assignedFastFacts[i].fast_fact.ID
        && this.assignedFastFacts[i].fast_fact.ID != null) {
          number += 1;
          this.$store.dispatch('getPost', { id: this.assignedFastFacts[i].fast_fact.ID, type: 'postFastFacts' });
        }
      });
      const array = Array.from(Array(number).keys());
      return array;
    },
  },
  watch: {
    getPost(newVal) {
      if (newVal !== null) {
        Object.keys(this.assignedFastFacts).forEach((i) => {
          if (this.assignedFastFacts[i].fast_fact.ID === newVal.ID) {
            // this.posts[i] = newVal; // this is for when updating to Vue3; V3 doesnt use $set()
            this.$set(this.posts, i, newVal);
          }
        });
        this.$store.commit('resetPost', { type: 'postFactFacts' });
      }
    },
    posts(newVal) {
      if (newVal.length === 3) {
        this.$store.commit('fastFactsLoaded', true);
        // this.loading = false;
      } else {
        // this.$store.commit('fastFactsLoaded', false);
        // this.loading = true;
      }
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.ms-grid {
  @media (max-width: $vp4) {
    padding-left: 0;
    padding-right: 0;
  }
}

.fast-facts-wrapper1 {
  border-top: 13px solid $red;
  margin: auto;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 3px 17px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  margin-top: -25px;
  @media (max-width: $vp3) {
    margin-bottom: 5px;
  }
}

.fast-facts-wrapper2 {
  padding: 35px 15px;
  background: #ffffff;
  @media (max-width: $vp3) {
    padding: 40px 5px;
  }
}

.fast-facts {
  // min-height: 200px;
  text-align: center;
}

ul.fast-facts {
  border: 0;
  display: flex;
  list-style-type:none;
  margin: 0 25px;
  @media (max-width: $vp4) {
    margin: 0 3%;
  }
  @media (max-width: $vp3) {
    margin: 0 5%;
  }
  @media (max-width: $vp2) {
    display: block;
    margin: 0 3%;
  }
  @media (max-width: $vp1) {
    display: block;
    margin: 0 3%;
  }
}

li.fast-facts {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin: 0 40px;
  width: 30%;
  @media (max-width: $vp4) {
    margin: 0 3%;
  }
  @media (max-width: $vp3) {
    margin: 0 3%;
  }
  @media (max-width: $vp2) {
    margin: 0 3%;
    width: 95%;
    margin-bottom: 20px;
  }
}

h1.fast-facts-text {
  color: $red;
  font-size: 55px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: $vp4) {
    font-size: 45px;
  }
  @media (max-width: $vp3) { // up to 768px
    font-size: 35px;
  }
  @media (max-width: $vp2) {
    font-size: 25px;
  }
}

h2.fast-facts-text {
  color: $navy;
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: $vp4) {
    font-size: 19px;
  }
  @media (max-width: $vp3) {
    font-size: 15px;
  }
}

span.fast-facts-text {
  color: $charcoal;
  font-size: 18px;
  line-height: 150%;
  @media (max-width: $vp4) {
    font-size: 15px;
  }
  @media (max-width: $vp3) {
    font-size: 13px;
  }
}

.fast-facts-text-horizontal-rule {
  background: $red;
  height: 4px;
  width: 100px;
  display: inline-block;
  margin-top: 17px;
  margin-bottom: 6px;
  @media (max-width: $vp3) {
    height: 2px;
    margin-top: 11px;
    margin-bottom: 0px;
    width: 80px;
  }
  @media (max-width: $vp2) {
    display: none;
    height: 1px;
    margin-top: 0;
    margin-bottom: 0;  }
}

.loading-animation {
  top: 50%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: #FFF;
  box-shadow: -24px 0 $red, 24px 0 #fff;
  -webkit-animation: circleCycle 2s linear infinite;
  animation: circleCycle 2s linear infinite;
}

@keyframes circleCycle {
  33% {
    background: $red;
    box-shadow: -24px 0 #fff, 24px 0 #FFF;
  }
  66% {
    background: #fff;
    box-shadow: -24px 0 #FFF, 24px 0 $red;
  }
  100% {
    background: #FFF;
    box-shadow: -24px 0 $red, 24px 0 #fff;
  }
}

</style>
